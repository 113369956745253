/* eslint-disable new-cap */
import React, { FC } from 'react';
// import rrwebPlayer from 'rrweb-player';
import { isBrowser } from '@/utils/browser';
import 'rrweb-player/dist/style.css';

const ShopSettingsPage: FC = () => {
  if (!isBrowser()) {
    return null;
  }

  // const dataPath = `https://logs.loomi-stg.xyz/record-web/PURETAKI/lx76agv8dq`;
  // const ref = useRef();
  //
  // useEffect(() => {
  //   (async () => {
  //     console.log(`foos`);
  //     const events = await (await fetch(dataPath)).json();
  //     const player = new rrwebPlayer({
  //       target: ref.current,
  //       props: {
  //         events,
  //       },
  //     });
  //     player.play();
  //   })();
  // }, []);

  // return <div ref={ref}>foo</div>;
  return <div>foo</div>;
};

export default ShopSettingsPage;
